import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

import Editpagesapling from 'components/register-plantation/editsapling';

// import Viewthubnailslider from 'components/register-plantation/viewthumbnail-slider';

// markup
const EditSapling = ({ location }) => {
  const dataValue = (location.state && location.state.registerData !== undefined) ? location.state.registerData : null;
  return (
    <>
      <SEO title='Forests By Heartfulness | Edit Sapling' />
      <Layout>
        <section id='editsapling'>
          <Editpagesapling data={dataValue} />
        </section>
      </Layout>
    </>
  );
};

export default EditSapling;
