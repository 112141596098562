import React, { useState, useEffect } from 'react';

import { useQueryParam, NumberParam, StringParam } from "use-query-params";

import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

import 'assets/styles/pages/plantationregister.scss';

import 'assets/styles/pages/plantationgrid.scss';

import Plantationbreadcumb from 'components/register-plantation/plantationbread';

import { truncate } from 'lodash';

import Arrowforward from 'assets/images/icons/arrow-forward.png';

import Arrowleftnext from 'assets/images/icons/arrow-left-next.png';

import plannedby from 'assets/images/icons/planned-by.png';

import plantedOn from 'assets/images/admin/plantedOn.svg';
import plantedBy from 'assets/images/admin/plantedBy.png';
import stateicon from 'assets/images/admin/stateicon.svg';
import village from 'assets/images/admin/village.svg';

import PlantationListDetails from 'services/plantation/plantation.service';
import { Modal, Spinner } from 'react-bootstrap';
import SaplingThumbnail from './saplingthumbnail-slider';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import noimage from "assets/images/noImage.png";
import closesmall from 'assets/images/icons/close-small-icon.svg';
import 'assets/styles/pages/plantationregister.scss';

import 'assets/styles/pages/plantationgrid.scss';

import 'assets/styles/pages/editspling.scss';

import mulspeciestree from 'assets/images/register-plantation/multiple-species-tree.png';
import { doLogout } from 'components/Auth';
import editiconpencil from 'assets/images/admin/edit-icon.svg';

import mapIcon from 'assets/images/admin/location.png'

import { navigate } from 'gatsby';
import Googlemaps from 'components/map-initiative/googlemaps';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
export default function Editpagesapling(props) {

  const [saplingId] = useQueryParam("id", NumberParam);
  const [gStatus] = useQueryParam("growthStatus", StringParam);
  const disabledStatus = gStatus === "true" ? true : false;
  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 2,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 1,
      partialVisibilityGutter: 150,
    },
  };
  const responsiveCard = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 2,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 1,
      partialVisibilityGutter: 130,
    },
  };
  const ds = new PlantationListDetails();


  const [plantationList, setPlantationList] = useState([]);
  const [loopArrayData, setLoopArrayData] = useState([]);
  const [detailsShown, setDetailsShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [signedList, setSignedList] = useState([]);
  const [saplingData, setSaplingData] = useState("");
  const [loadViewData, setLoadViewData] = useState("");
  const [pathImage, setPathImage] = useState([]);
  const [allImage, setAllImage] = useState([]);
  const [filesDataList, setFilesDataList] = useState([]);
  const [saveDisable, setSaveDisable] = useState([]);
  const [geoLocationOpen, setGeoLocationOpen] = useState(false);
  const [locationValue, setLocationValue] = useState(false);

  const geoLocation = async (data) => {
    setGeoLocationOpen(true)
    let values = {
      latitude: data.latitude,
      longitude: data.longitude,
    }
    setLocationValue(values)
  }

  const loadData = async (e) => {
    await setAllImage([])
    await setLoading(true)
    await setLoadViewData(e)
    var result = await e.trackingSpeciesDetails.map(person => ({
      aliveCount: person.aliveCount && person.aliveCount ? person.aliveCount : 0,
      id: person.id,
      replaceCount: person.replacedCount && person.replacedCount ? person.replacedCount : 0,
      deadCount: person.deadCount && person.deadCount ? person.deadCount : 0,
      height: person.growthStatus && person.growthStatus.attributeMap ? person.growthStatus.attributeMap.height : 0,
      girth: person.growthStatus && person.growthStatus.attributeMap ? person.growthStatus.attributeMap.girth : 0,
      speciesName: person.species && person.species.speciesName ? person.species.speciesName : 0,
      speciesCount: person.speciesCount && person.speciesCount ? person.speciesCount : 0,

    }));
    const imagesList = e.imagesLogs.map(item => {
      return item.imageUrl
    })
    setAllImage([...imagesList]);

    await setLoopArrayData(result)
    setDetailsShown(true)
    //    await setFilesList(e.imagesLogs)
    await setLoading(false);
  }
  const closeData = () => {
    setDetailsShown(false)
    window.scrollTo(0, 0)
  }

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className='carousel-button-group'>
        <div className='navigations'>
          <button className='right-butt-custom' onClick={() => previous()}>
            <img src={Arrowleftnext} alt='LeftArrowforward' />
          </button>
          <button className='left-butt-cutom' onClick={() => next()}>
            <img src={Arrowforward} alt='LeftArrowforward' />
          </button>
        </div>
      </div>
    );
  };

  const saveImg = async () => {
    setLoading(true)
    const plantationResponse = await ds.imagesSignedUrl({ signedUrlDetails: signedList });
    if (plantationResponse !== undefined) {
      if (plantationResponse.status === 200) {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong");
    }
    await imageUploadCall(plantationResponse.data.signedUrlDetails);
  }

  const imageUploadCall = async (data) => {
    setLoading(true)
    await data.map((item, index) => {

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/octet-stream");
      myHeaders.append("x-goog-acl", "public-read");

      let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: filesList[index],
        redirect: 'follow'
      };
      setLoading(true)
      fetch(item.signedUrl, requestOptions).then(result => {
        var obj = {};
        obj["pictureUrl"] = data[index].objectPath;
        pathImage.push(obj);
      })
    })
    setTimeout(function () {
      setLoading(true);
    }, 10000)
  }
  const fileLoad = async (e) => {
    if (e.target.files.length !== 0) {
      setLoading(true);

      const name = e.target.files[0].name;
      const lastDot = name.lastIndexOf('.');

      const fileName = name.substring(0, lastDot);
      const file = e.target.files[0];
      const filetype = e.target.files[0].type;
      let fileExtension = file.type.split("/").pop();
      let imgData = {
        "fileName": fileName,
        "fileExtension": fileExtension,
        "fileType": filetype
      }
      const filesData = [
        ...filesList,
        file,
      ]
      const signedData = [
        ...signedList,
        imgData,
      ]
      setFilesDataList([...filesDataList, e.target.files[0]])
      setFilesList(filesData);
      // setAllImage([...allImage, URL.createObjectURL(e.target.files[0])]);
      setSignedList(signedData)
      setLoading(false)
    }
  }
  const loadSaplingData = async (id) => {
    setLoading(true)
    await ds.listSpeicess(id)
      .then(data => {
        if (data.status === 200) {
          setPlantationList(data.data.trackingDetails);
          setSaplingData(data.data);
          setLoading(false);
          window.scrollTo(0, 0)
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 404) {
            toast.error("Authentication failed");
            setTimeout(function () {
              doLogout()
              navigate("/")
              setLoading(false);
            }, 3000)
          }
        }
        window.scrollTo(0, 0)
      }
      );
  };

  const countChange = (data, key, item) => {
    let totalCount;
    if (key === "aliveCount") {
      totalCount = Number(data) + Number(item.deadCount) + Number(item.replaceCount)
    }
    if (key === "deadCount") {
      totalCount = Number(item.aliveCount) + Number(data) + Number(item.replaceCount)
    }
    if (key === "replaceCount") {
      totalCount = Number(item.aliveCount) + Number(item.deadCount) + Number(data)
    }
    if (totalCount > item.speciesCount) {
      item['countError'] = true;
      setSaveDisable(true)
    } else {
      delete item['countError'];
      setSaveDisable(false)
    }
    const existData = [...loopArrayData];
    const updateValue = { ...item, [key]: data };
    const indexOf = existData.findIndex((d) => d.id === item.id);
    existData[indexOf] = updateValue;
    setLoopArrayData(existData);
    setLoading(false);

  }

  const saveSaplingUpdate = async (id) => {
    if (filesDataList.length === 0) {
      setLoading(true)
      let finalValue = loopArrayData.map(item => ({
        aliveCount: item.aliveCount && item.aliveCount ? item.aliveCount : null,
        trackingSpeciesDetailId: item.id && item.id ? item.id : null,
        replaceCount: item.replaceCount && item.replaceCount ? item.replaceCount : null,
        deadCount: item.deadCount && item.deadCount ? item.deadCount : null,
        attributeMap: {
          height: item && item ? item.height : null,
          girth: item && item ? item.girth : null,
        },
        speciesName: item && item.speciesName ? item.speciesName : null,
        speciesCount: item && item.speciesCount ? item.speciesCount : null,
      }))

      let updateData = {
        "trackingId": loadViewData.trackingId,
        "speciesGrowthStatusDetails": [...finalValue],
        "pictureDetails": []
      }
      ds.speciesGrowth(updateData)
        .then(data => {
          if (data.status === 200) {
            toast.success("Spcecies updated Successfully");
            setLoading(false);
            loadSaplingData(saplingId)
            setDetailsShown(false)
          } else {
            setLoading(false)
            toast.error("Something went wrong");
          }
        })
        .catch(error => {
          setLoading(false)
          toast.error(error.response.data.message);
        });
      window.scrollTo(0, 0)
    } else {
      await saveImg();
      await setTimeout(function () {
        let finalValue = loopArrayData.map(item => ({
          aliveCount: item.aliveCount && item.aliveCount ? item.aliveCount : null,
          trackingSpeciesDetailId: item.id && item.id ? item.id : null,
          replaceCount: item.replaceCount && item.replaceCount ? item.replaceCount : null,
          deadCount: item.deadCount && item.deadCount ? item.deadCount : null,
          attributeMap: {
            height: item && item ? item.height : null,
            girth: item && item ? item.girth : null,
          },
          speciesName: item && item.speciesName ? item.speciesName : null,
          speciesCount: item && item.speciesCount ? item.speciesCount : null,
        }))

        let updateData = {
          "trackingId": loadViewData.trackingId,
          "speciesGrowthStatusDetails": [...finalValue],
          "pictureDetails": pathImage
        }
        ds.speciesGrowth(updateData)
          .then(data => {
            if (data.status === 200) {
              // setPlantationList(data.data.trackingDetails);
              // setSaplingData(data.data);
              toast.success("Spcecies updated Successfully");
              setLoading(false);
              loadSaplingData(saplingId)
              setDetailsShown(false)
            } else {
              setLoading(false)
              toast.error("Something went wrong");
            }
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message);
          }
          );
      }, 10000)
      window.scrollTo(0, 0)
    }
  };

  const imageRemove = (e) => {
    let myArray;
    let infoArray;
    myArray = filesList.filter(function (obj) {
      return obj.name !== e.currentTarget.id;
    });
    setFilesList(myArray)
    infoArray = signedList.filter(function (obj) {
      const lastDot = e.currentTarget.id.lastIndexOf('.');
      const fileName = e.currentTarget.id.substring(0, lastDot);
      return obj.fileName !== fileName;
    });
    setSignedList(infoArray)
  }
  useEffect(() => {
    loadSaplingData(saplingId)
  }, []);
  return (
    <>
      <div>
        <Plantationbreadcumb backTo="/planter-details" name="Edit Sapling" />
      </div>
      <div className='plantation-breadsection'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 padding-mobile'>
              <div className='title-inline'>
                <h3 className='title-item'> {saplingData && saplingData.project !== "" ? <> {saplingData.project.name}</> : ""} </h3>
                <h3 className='bred-regid view-pro-name'> Reg Id #{saplingData && saplingData !== "" ? <> {saplingData.registrationId}</> : ""} </h3>
                <h5 className='bred-datetime'> {saplingData && saplingData !== "" ? <>  {moment(new Date(saplingData.plantationDate)).format(("ddd, MMM D YYYY"))}</> : ""} </h5>
              </div>

              <div className='dis-in-planed'>
                <span className='dis-image-item'>
                  <img src={plantedOn} alt='treeRightIconGreen' />
                </span>

                <h5 className='planed-text'>Planted On:</h5>
                <h5 className='date-calc-text'>{saplingData && moment(new Date(saplingData.plantationDate)).format(("ddd, MMM D YYYY"))}</h5>
              </div>

              <div className='dis-in-planed'>
                <span className='dis-image-item'>
                  <img src={plantedBy} alt='treeRightIconGreen' />
                </span>

                <h5 className='planed-text'>Planted By:</h5>
                <h5 className='date-calc-text'> {saplingData.planterName !== "" ? saplingData.planterName : "No Name"}</h5>
              </div>

              <div className='dis-in-planed'>
                <span className='dis-image-item'>
                  <img src={village} alt='treeRightIconGreen' />
                </span>

                <h5 className='planed-text'>Village/city </h5>
                <h5 className='date-calc-text'>{saplingData && saplingData.attributeMap.city}</h5>
              </div>

              <div className='dis-in-planed'>
                <span className='dis-image-item'>
                  <img src={stateicon} alt='treeRightIconGreen' />
                </span>

                <h5 className='planed-text'>State: </h5>
                <h5 className='date-calc-text'> {saplingData && saplingData.attributeMap.state}</h5>
              </div>
            </div>
          </div>
          {plantationList && plantationList.length > 0 ?
            <div className='row mg-top20'>
              <div className='carousel-section' id="carousel-bg">
                <Carousel
                  arrows={true}
                  renderButtonGroupOutside={false}
                  responsive={responsive}
                // customButtonGroup={<ButtonGroup />}
                >
                  {plantationList && plantationList.map((item) => {
                    const count = item.trackingSpeciesDetails.reduce((counter, obj) => counter += obj.speciesCount, 0); // 6
                    return (
                      <div className='iniative-card' key={item.id} data-value={item.id} value={item.id}>
                        <div className='wid-55' >
                          {item.imagesLogs && item.imagesLogs ?
                            <SaplingThumbnail images={item.imagesLogs} /> : <img src={noimage} alt="default Image" />}
                        </div>

                        <div className='iniativecard-body wid-45 hand-pointer' id="card-val" key={item.id}>
                          <div className='text-right icon-rightposi pad-5'>
                            <img src={mapIcon} className='img-fluid hand-pointer' width="15px" alt='Edit Icon' onClick={() => geoLocation(item)} /> &nbsp;
                            <img src={editiconpencil} className='img-fluid hand-pointer' width="15px" alt='Edit Icon' onClick={() => loadData(item)} /> &nbsp;
                          </div>
                          <p className='text-city'>Track Id </p>
                          <p className='text-city'><b># {item.trackingId}
                          </b>
                          </p>
                          <p className='text-city'></p>
                          <p className='text-city tree-count'><b>{count} Trees</b></p>
                          {/* <div className='plant-type'>Plant Type</div>
                          <p className='text-city'><b>
                            {truncate(item.plantType, {
                              length: 100,
                              omission: '...',
                            })}
                          </b>
                          </p> */}
                          <div className='plant-type'>Spices</div>
                          {item.trackingSpeciesDetails.map((data, index) => {
                            return <b><span className='text-city'>{data.speciesCount} {data.species.speciesName} {index === item.trackingSpeciesDetails.length - 1 ? "" : ", "}</span></b>
                          })}

                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div> : null}
          {detailsShown ?
            <div className='row'>
              <div className='col-md-12 mg-top30'>
                <h3 className='iniativecard-title'> ADD IMAGES </h3>

                <div className='dis-flex add-more-img-posi'>
                  {/* {allImage.length > 1 ? <> {allImage && allImage.map(data => {
                    return (<img src={data} className='img-fluid' alt='Add image List' width="140px" height="100px" />)

                  })}</> :

                    <img src={allImage[0]} className='img-fluid' alt='Add image List' width="140px" height="100px" />

                  } */}
                  <SimpleReactLightbox>
                    <SRLWrapper>

                      {allImage && allImage.map(data => {
                        return (
                          <div className='recent-plantation-section-plantation'>
                            <div className='img-wrapper img_wrp hand-pointer' key={data}>
                              <img src={data} className='img-fluid' alt='' width="140px" height="100px" />
                              <div className='overlay overLay-img'>
                                <i className='icon icon-arrows-fullscreen'></i>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </SRLWrapper>
                  </SimpleReactLightbox>
                  {filesList.length !== 0 ? <> {filesList && filesList.map(data => {
                    return (<div className='img_wrp'>
                      <span className='small-close small-close-images hand-pointer close' >
                        <div id={data.name} onClick={imageRemove}> <img src={closesmall} className='img-fluid ' alt='Close small' />{' '}</div>
                      </span>
                      <img src={URL.createObjectURL(data)} className='img-fluid' alt='Add image List' width="140px" height="100px" />
                    </div>)

                  })}</> : null}
                  <div className='border-items'>
                    <span className='plus-icon-green btn-file'>
                      <i className='icon icon-plus-lg plus-symbol-custom-icon'></i>
                      <input type="file" onChange={fileLoad} accept="image/png, image/gif, image/jpeg" />Add more
                    </span>
                    {/* <p><input type="file" onChange={fileLoad} /> Add more</p> */}
                  </div>
                </div>
              </div>

              <div className='col-md-12'>
                {/* <Viewsaplingtreecarosel /> */}

                {/* <Editmultiplespecies item={loadViewData.trackingSpeciesDetails} id={loadViewData.trackingId} /> */}
                <div className='multiple-species-wrapper'>
                  <br />
                  <h3 className='iniativecard-title'>Multiple Species</h3>
                  <br />
                  <div className='row'>
                    <div className='intro-carousel2' id="carousel-bg">
                      <Carousel
                        arrows={true}
                        minimumTouchDrag={80}
                        partialVisible
                        renderButtonGroupOutside={false}
                        responsive={responsiveCard}
                      >
                        {loopArrayData.map((item, index) => {
                          return (<div className='col-md-6 multiple-species-box-wrapper multiple-species-box-wrapper-edit' key={index + 1}>
                            <div className='dis-flex icon-species-text'>
                              <span className='mulspecies-tree'>
                                <img src={mulspeciestree} alt='treeRightIconGreen' />
                              </span>

                              <div className='margin-top-15'>
                                <h5 className='surving-item'> Your <b>{item.speciesName}</b> Tree </h5>
                                <p class="text-city tree-count"> {item.speciesCount} Trees</p>
                              </div>
                            </div>
                            <div className='list-species-item' disabled={disabledStatus}>
                              <div className='wid-50'>
                                <div className='fc-wrapper'>
                                  <label htmlFor='survivingcount' className='form-label form-control-border surving-item left-move'>
                                    Species surviving count
                                  </label>
                                  <input
                                    type='number'
                                    name='survivingcount'
                                    disabled={disabledStatus}
                                    className='form-control'
                                    id='survivingcount'
                                    placeholder='0'
                                    value={item.aliveCount}
                                    onChange={(e) => { countChange(e.target.value, "aliveCount", item) }}
                                  />
                                </div>
                                <div className='fc-wrapper'>
                                  <label htmlFor='notsurvivingcount' className='form-label form-control-border surving-item left-move'>
                                    Species not surviving count
                                  </label>
                                  <input
                                    type='number'
                                    name='notsurvivingcount'
                                    className='form-control '
                                    disabled={disabledStatus}
                                    id='notsurvivingcount'
                                    placeholder='0'
                                    value={item.deadCount}
                                    onChange={(e) => { countChange(e.target.value, "deadCount", item) }}
                                  />
                                </div>
                                <div className='fc-wrapper'>
                                  <label htmlFor='replacedCount' className='form-label form-control-border surving-item left-move'>
                                    Species replaced with new
                                  </label>
                                  <input
                                    type='number'
                                    name='replacedCount'
                                    className='form-control '
                                    disabled={disabledStatus}
                                    id='replacedCount'
                                    placeholder='0'
                                    value={item.replaceCount}
                                    onChange={(e) => { countChange(e.target.value, "replaceCount", item) }}
                                  />
                                </div>
                              </div>
                              <div className='wid-50'>
                                <div className='fc-wrapper'>
                                  <label htmlFor='survivingcount' className='form-label form-control-border surving-item left-move'>
                                    Height (cm)
                                  </label>
                                  <input
                                    type='number'
                                    name='Height'
                                    className='form-control'
                                    id='height'
                                    disabled={disabledStatus}
                                    placeholder='0'
                                    pattern="^\d*(\.\d{0,2})?$"
                                    //   value={item.growthStatus && item.growthStatus.attributeMap.height !== undefined ? item.growthStatus.attributeMap.height : ""}
                                    value={item.height}
                                    onChange={(e) => { countChange(e.target.value, "height", item) }}
                                  />
                                </div>
                                <div className='fc-wrapper'>
                                  <label htmlFor='notsurvivingcount' className='form-label form-control-border surving-item left-move'>
                                    Girth / DBH (cm)
                                  </label>
                                  <input
                                    type='number'
                                    name='girth'
                                    className='form-control '
                                    id='girth'
                                    placeholder='0'
                                    pattern="^\d*(\.\d{0,2})?$"
                                    value={item.girth}
                                    disabled={disabledStatus}
                                    onChange={(e) => { countChange(e.target.value, "girth", item) }}
                                  />
                                </div>
                              </div>
                            </div>
                            {item.countError === true ? <p className="form-error">Total count is not more than total tree {item.speciesCount} count</p> : null}
                          </div>)
                        })}
                      </Carousel>
                    </div>
                  </div>

                </div>

                <div className='text-right mg-top20 display-button-order align_center-card'>
                  <button className='btn edit-save-btn save-width posi-close-top mag-rgt-10' disabled={disabledStatus || saveDisable === true} onClick={saveSaplingUpdate}> Save </button>
                  <button className='btn btn-approve save-width posi-close-top' onClick={closeData}> Close </button>
                </div>
              </div>
            </div> : null}
        </div>
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <Modal
        show={geoLocationOpen}
        id="mappop"
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        className='tracklocation-popup'
        onHide={() => setGeoLocationOpen(false)}


        centered>
        <Modal.Header closeButton='close-button topright'></Modal.Header>
        <Modal.Body id="mappop">
          <div id="mappop" className='map-page plantpopup-page'>
            <Googlemaps mapValues={{ 'mapValues': locationValue }} />
            <div className='container'>
              <div className='row'>

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
